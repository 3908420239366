import React from "react"
import AddToCart from "../AddToCart/AddToCart"
// import OutOfStock from "../Cart/Buttons/OutOfStock"
import { getSrc } from "gatsby-plugin-image"
import * as styles from "./Product.module.scss"

import ReactMarkdown from "react-markdown"




// here shopify data is transfered through gatsby-node
const Product = ({product, cmsData}) => {
	
	const productShot = getSrc(product.images[0])
	const price = product?.variants[0]?.price
	if (cmsData) {
		console.log('CREATING PRODUCT SUCCESS:','[', product.title,']')
		return(
			<div className={styles.wrapper}>
				
					<div className={styles.imageWrapper}>
						<img src={productShot}/>
						<div className={styles.addToCartContainerMobile}>
						<AddToCart product={product}/>
						</div>	
					</div>
					
				<div className={styles.informationWrapper}>
					<h1 className={styles.productTitle}>{product.title}</h1>
					<p className={styles.productDetails}>{product.productType},</p>
					<p className={styles.productDetails}>{product.options[0]?.values[0]}</p>
					<p className={styles.productPrice}>€{price}</p>
					
					<ReactMarkdown className={styles.cmsInfoText} children={cmsData.information} /> 
					<div className={styles.addToCartContainer}>
						<AddToCart product={product} />
					</div>
				</div>

				<section>
					{cmsData.content?.map((section) => {
						switch(true) {
							case Boolean(section.type === 'fullImage'):
							return (
								<div className={styles.doubleImageWrap}>
								<img className={styles.fullImage} src={getSrc(section.fullImage)}/>
								</div>
							)
							case Boolean(section.type === 'image'):
							return (
								<div className={styles.singleImageWrap}>
									<img key={section.image} src={getSrc(section.image)} alt="" />
								</div>
							)
							case Boolean(section.type === 'windowText'):
								return (
								<ReactMarkdown className={styles.windowText} children={section.windowText} >
									{section.windowText}
				                </ReactMarkdown>   
								)
							case Boolean(section.type === 'fullText'):
								return (
								<ReactMarkdown className={styles.fullText} children={section.fullText} >
									{section.fullText}
				                </ReactMarkdown>  
								)
							default: 
								return (
								<>Data type not recognized</>
								)
							
						}
					})}
				
				</section>
				<div className={styles.bottomSection}>
					<ReactMarkdown className={styles.windowText} children={cmsData.productWindowText} >
						{cmsData.productWindowText}
					</ReactMarkdown>
					{ cmsData.contentBottom?.map((img) => {
						let imgSrc = getSrc(img.image)
						return (
							<div className={styles.bottomProductCMSImage}>
								<img key={img} src={imgSrc} alt="" />
							</div>
						)})
					}
            	</div>	
			</div>
		)
	} else {
        return <div>Loading...</div>
    }
}


export default Product
