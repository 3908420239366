import React from "react"
import StoreContext from "../../StoreContext/index"
import { triggerCart, addCartItem } from "../Cart/HelperFns"
import styled from "@emotion/styled"
import * as styles from "./addToCart.module.scss"
import arrow from "../../../static/fonts/Arrow.svg"


var { useState, useContext } = React

const AddToCart = (props) => {
	//  get access to all of the product details passed from product template

	var { product } = props

	//  by default just use the first variant that shopify supplies. only update if a user selects a different one.
	var [variant, setVariant] = useState(product.variants[0])

	//  start product amount at 1
	var [amount, setAmount] = useState(1)

	//  retrieve the current store context;
	var [store, setStore] = useContext(StoreContext)
	var { added } = store

	// a function to increment cart items.
	const incAmount = () => {
		setAmount((curState) => {
			return (curState += 1)
		})
	}

	// a function to increment cart items.
	const decAmount = () => {
		setAmount((curState) => {
			return (curState -= 1)
		})
	}

	const initialText = 'ADD TO CART';
	const [buttonText, setButtonText] = useState(initialText);
  
	function handleClick() {
	  setButtonText('ADDING...');
	  
	  clearTimeout(timeout)
	  let timeout = setTimeout(() => {
		setButtonText(initialText);
	  }, 700); // 👈️ change text back after 1 second
	}

	const handleAddToCart = () => {

		addCartItem(product, variant, amount, added, setStore)
		triggerCart(setStore)
		handleClick()

	}
	return (
		<>
			
				{variant && variant.availableForSale ? (
					<div onClick={handleAddToCart} className={`${styles.addToCartBtn} ${styles.available}`}>
						<span className={styles.price}>€{variant.price}</span>
							<div className={styles.parentDiv}>
								<span>{buttonText}</span>
								<img className={styles.arrow} src={arrow}/>
							</div>
					</div>

				) : (
					<div className={`${styles.addToCartBtn} ${styles.soldOut}`}>
						<span className={styles.price}>€{variant.price}</span>
						<span>SOLD OUT</span> <img className={styles.arrow} src={arrow}/>
					</div>
				)}
		</>
	)
}

export default AddToCart
